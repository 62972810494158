/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-north-1",
    "aws_appsync_graphqlEndpoint": "https://arfmzkzs5jhvhn3ujkri7bfa6a.appsync-api.eu-north-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-north-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-north-1:b45c1c9f-85cb-4be9-ba8c-d53b9f7d8a66",
    "aws_cognito_region": "eu-north-1",
    "aws_user_pools_id": "eu-north-1_EU3dDZcdS",
    "aws_user_pools_web_client_id": "5a6dcp394h20rjsm6a7lt71v7c",
    "oauth": {
        "domain": "d1lgkxx81fmfg-main.auth.eu-north-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://ea.ziklo.cloud/",
        "redirectSignOut": "https://ea.ziklo.cloud/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
